import { Layout } from 'antd';
import { enquireScreen } from 'enquire-js';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { MainHeader } from './MainHeader';

export const MainLayout: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    enquireScreen((b) => {
      if (b) {
        setIsMobile(true);
      }
    });
  }, []);

  return (
    <>
      <Layout>
        <MainHeader isMobile={isMobile} />
        <main>{children}</main>
      </Layout>
    </>
  );
};
