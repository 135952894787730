import { datadogRum } from '@datadog/browser-rum';
import type { CSSInterpolation } from '@emotion/serialize';
import styled from '@emotion/styled';
import { notify } from '@genteras/ui-lib';
import { Turnstile } from '@marsidev/react-turnstile';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import type { FC } from 'react';
import React, { useState } from 'react';
import UserAPI from '@/lib/api/user';
import { StyledInput } from '../../components/base/StyledInput';
import { urls } from '../../constant/urls';
import { commonConfig } from '../../features/system/common.config';

type Props = {
  onFinish: () => void;
};

interface FormData {
  username: string;
  password: string;
}

const FormItem = styled(Form.Item)({
  alignContent: 'center',
  padding: '2px',
  paddingBottom: '10px',
});

const popupFormCss: CSSInterpolation = {
  ['.ant-form-item']: {
    alignContent: 'center',
    marginBottom: 0,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
};

const StyledButtonThick = styled(Button)`
  display: block !important;
  border-radius: 50px !important;
  background: linear-gradient(
    to left,
    rgb(184, 132, 34) 0%,
    #7e5700 100%
  ) !important;
  height: 40px !important;
  color: white !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-width: 80px;
  align-items: center;
  justify-content: center;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
`;

const LoginForm: FC<Props> = ({ onFinish }: Props) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const RECAPTCHA_SITE_KEY =
    process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY || '';
  const recaptchaRef = React.useRef();

  const handleSubmit = async (values: FormData) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line
      const token = (recaptchaRef.current as any)?.getResponse();
      const loginData = {
        username: values.username,
        password: values.password,
        types: ['Subagent', 'Subaccount'],
        recaptchaToken: token,
      };
      const data = await UserAPI.login(loginData);

      onFinish();

      datadogRum.setUser({
        id: data.id,
      });
      form.resetFields();
      Router.push(urls.boOverview);
      notify.success({
        message: t('common:LoginSuccess'),
      });
    } catch (e: unknown) {
      if (
        axios.isAxiosError(e) &&
        e?.response?.data?.message === 'Unauthorized'
      ) {
        notify.error({
          message: t('common:IncorrectUsernameOrPassword'),
          t,
        });
      } else if (
        axios.isAxiosError(e) &&
        e?.response?.data?.message === 'Low recaptcha score.'
      ) {
        notify.error({
          message: t('common:error.LOW_RECAPTCHA'),
          t,
        });
      } else {
        notify.error({
          message: t('common:GenericFailureMsg'),
          description: t('common:SomethingWentWrong'),
          e,
          t,
        });
        throw e;
      }
    } finally {
      setIsLoading(false);
      // eslint-disable-next-line
      (recaptchaRef.current as any)?.reset();
    }
  };

  return (
    <Form
      form={form}
      css={popupFormCss}
      name={'login'}
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      autoComplete="off"
    >
      <FormItem
        name="username"
        rules={[{ required: true, message: t('common:UsernameInput') }]}
      >
        <StyledInput
          style={{ height: '40px' }}
          placeholder={t('common:username')}
        />
      </FormItem>

      <FormItem
        name="password"
        rules={[{ required: true, message: t('PleaseInputPassword') }]}
      >
        <Input.Password
          style={{ height: '40px', borderRadius: '50px' }}
          placeholder={t('common:password')}
        />
      </FormItem>

      <FormItem>
        <Turnstile ref={recaptchaRef} siteKey={RECAPTCHA_SITE_KEY} />
        <StyledButtonThick
          style={{ width: '100%' }}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {t('common:Login')}
        </StyledButtonThick>
      </FormItem>
    </Form>
  );
};

export default LoginForm;
