import styled from '@emotion/styled';
import { Dropdown, Grid, Layout, Space } from 'antd';
import Link from 'next/link';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { SiteConfig } from '../../config';
import config, { Sites } from '../../config';
import { LanguageDropdown } from '../common/LanguageDropdown';

const { Header } = Layout;
const { useBreakpoint } = Grid;

const LightHeader = styled(Header)({
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  ['@media (max-width: 767px)']: {
    padding: '0 20px',
  },
});

const HeaderLeft = styled.div({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const HeaderRight = styled.div({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
});

const Logo = styled.div({
  cursor: 'pointer',
});

const LogoLink = styled(Link)({
  display: 'inline-block',
  fontSize: '20px',
  textTransform: 'capitalize',
});

export const MenuDropdown = styled(Dropdown)({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});

export const MenuDropdownOverlayCtn = styled.div({
  boxShadow:
    '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
});

type Props = {
  isMobile: boolean;
};

export const MainHeader: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { xs } = useBreakpoint();

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeaderAdmin: '',
    logoHeaderIndex: '',
    logoSmall: '',
    agentSiteLogo: '',
    agentSiteLink: '',
  });
  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);
  return (
    <LightHeader>
      <HeaderLeft>
        <Space direction="horizontal" align="center" size={isMobile ? 2 : 15}>
          {siteConfig.siteName !== 'SA36' &&
          siteConfig.logoHeaderIndex &&
          siteConfig.logoSmall ? (
            <Logo>
              <LogoLink href={'/'}>
                {xs ? (
                  <img
                    src={siteConfig.logoSmall}
                    alt="logo"
                    style={{ maxWidth: '100%', height: 35 }}
                  />
                ) : (
                  <img src={siteConfig.logoHeaderIndex} alt="logo" />
                )}
              </LogoLink>
            </Logo>
          ) : null}
          {siteConfig.siteName === 'SA36' &&
          siteConfig.logoHeaderIndex &&
          siteConfig.logoSmall ? (
            <Logo style={{ width: '100px' }}>
              <LogoLink href={'/'}>
                {xs ? (
                  <img
                    src={siteConfig.logoSmall}
                    alt="logo"
                    style={{ maxWidth: '100%', height: 35 }}
                  />
                ) : (
                  <img
                    src={siteConfig.logoHeaderIndex}
                    alt="logo"
                    style={{ width: '150px', marginTop: '50px' }}
                  />
                )}
              </LogoLink>
            </Logo>
          ) : null}
        </Space>
      </HeaderLeft>
      <HeaderRight>
        <Space direction="horizontal" align="center" size={isMobile ? 2 : 15}>
          <LanguageDropdown />
        </Space>
      </HeaderRight>
    </LightHeader>
  );
};
