import styled from '@emotion/styled';
import { BadRequest } from '@tsed/exceptions';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { MainFooter } from '@/components/layout/MainFooter';
import LoginForm from '@/components/profile/LoginForm';
import { urls } from 'constant/urls';
import { MainLayout } from '../components/layout/MainLayout';
import type { SiteConfig } from '../config';
import config, { Sites } from '../config';
import { homeConfig } from '../features/system/home.config';

type Props = {
  /** Add HomeRoute props here */
};

const BGDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const LoginContainer = styled.div({
  '@media (min-width: 1140px)': {
    minWidth: '30%',
  },
  '@media (max-width: 1139px)': {
    minWidth: '50%',
  },
  '@media (max-width: 960px)': {
    minWidth: '60%',
  },
  '@media (max-width: 576px)': {
    minWidth: '80%',
  },
  justifyContent: 'center',
  marginTop: '80px',
  background: 'rgba(189,189,189,0.5)',
  textAlign: 'center',
  border: '2px solid #ffffff',
  borderRadius: '15px',
  display: 'table',
});

const LoginContainer2 = styled.div({
  marginTop: '60px',
  paddingBlock: '50px',
});

export default function DemoRoute(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const router = useRouter();
  useEffect(() => {
    if (window.location.hostname === 'www.aieias.net') {
      router.push(urls.casa98);
    }
  }, [router]);

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeaderAdmin: '',
    logoHeaderIndex: '',
    logoSmall: '',
    agentSiteLogo: '',
    agentSiteLink: '',
  });
  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);
  return (
    <div className={'index'}>
      <MainLayout>
        <BGDiv>
          <LoginContainer>
            <LoginContainer2>
              <LoginForm
                onFinish={() => {
                  return;
                }}
              />
              {siteConfig.siteName !== 'SA36' &&
              siteConfig.agentSiteLogo &&
              siteConfig.agentSiteLink ? (
                <Link href={siteConfig.agentSiteLink}>
                  <a>
                    <img
                      src={siteConfig.agentSiteLogo}
                      alt="logo"
                      style={{
                        maxWidth: '100%',
                        height: 35,
                        marginTop: '50px',
                      }}
                    />
                  </a>
                </Link>
              ) : null}
              {siteConfig.siteName === 'SA36' &&
              siteConfig.agentSiteLogo &&
              siteConfig.agentSiteLink ? (
                <Link href={siteConfig.agentSiteLink}>
                  <a>
                    <img
                      src={siteConfig.agentSiteLogo}
                      alt="logo"
                      style={{
                        maxWidth: '100%',
                        height: 70,
                        marginTop: '50px',
                      }}
                    />
                  </a>
                </Link>
              ) : null}
            </LoginContainer2>
          </LoginContainer>
        </BGDiv>
      </MainLayout>
      <MainFooter />
    </div>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new BadRequest('locale is missing');
  }
  const { i18nNamespaces } = homeConfig;
  return {
    props: {
      ...(await serverSideTranslations(locale, i18nNamespaces.slice())),
    },
  };
};
